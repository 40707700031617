<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Equipment'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="pabrik_id_label"
                  label="Pabrik :"
                  label-for="pabrik_id"
                >
                  <b-form-select
                    id="pabrik_id"
                    @input="getPlant"
                    v-model="$v.form.pabrik_id.$model"
                    :options="pabrik"
                    :state="validateState('pabrik_id')"
                  ></b-form-select>
                  <b-form-invalid-feedback id="pabrik_id_feedback">
                    Harap Memilih Salah Satu Pabrik
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="plant_id_label"
                  label="Plant :"
                  label-for="plant_id"
                >
                  <b-form-select
                    id="plant_id"
                    v-model="$v.form.plant_id.$model"
                    :options="plant"
                    :state="validateState('plant_id')"
                  ></b-form-select>

                  <b-form-invalid-feedback id="pabrik_id_feedback">
                    Harap Memilih Salah Satu Plant
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nama_equipment_label"
                  label="Nomor Equipment :"
                  label-for="nomor_equipment"
                >
                  <b-form-input
                    id="nomor_equipment"
                    v-model="form.nomor_equipment"
                    type="text"
                    placeholder="Enter Number..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nomor_equipment_label"
                  label="Nama Equipment :"
                  label-for="nomor_equipment"
                >
                  <b-form-input
                    id="nama_equipment"
                    v-model="$v.form.nama_equipment.$model"
                    type="text"
                    placeholder="Enter Text..."
                    :state="validateState('nama_equipment')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="nama_equipment_feedback">
                    Nama Equipment Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="jenis_equipment_label"
                  label="Jenis Equipment :"
                  label-for="jenis_equipment_id"
                >
                  <vue-suggestion
                    :items="jenis_equipment"
                    v-model="itemJenisEquipment"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChangeJenisEquipment"
                    @selected="itemSelectedJenisEquipment"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                  >
                  </vue-suggestion>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="design_code_label"
                  label="Design code :"
                  label-for="design_code_id"
                >
                  <vue-suggestion
                    :items="design_code"
                    v-model="itemDesignCode"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChange"
                    @selected="itemSelected"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                  >
                  </vue-suggestion>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="manufacturer_label"
                  label="Manufacturer :"
                  label-for="manufacturer"
                >
                  <b-form-input
                    id="manufacturer"
                    v-model="form.manufacturer"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="tgl_mulai_operasi_label"
                  label="Tanggal Mulai Operasi :"
                  label-for="tgl_mulai_operasi"
                >
                  <b-form-input
                    id="tgl_mulai_operasi"
                    v-model="form.tgl_mulai_operasi"
                    type="date"
                    placeholder="Enter Date..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="penggantian_terakhir_label"
                  label="Penggantian Terakhir :"
                  label-for="penggantian_terakhir"
                >
                  <b-form-input
                    id="penggantian_terakhir"
                    v-model="form.penggantian_terakhir"
                    type="date"
                    placeholder="Enter Date..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="penggantian_ke_label"
                  label="Penggantian ke :"
                  label-for="penggantian_ke"
                >
                  <b-form-input
                    id="penggantian_ke"
                    v-model="form.penggantian_ke"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-alert class="pl-2" variant="danger" :fade="true" v-model="showDismissibleAlert">
                  <span v-for="(val, index) in errors" :key="index">
                    <h6>{{ index.replace(/_/g, ' ').toUpperCase() + ':' }}</h6>
                    <ul>
                      <li v-for="(arr, i) in val" :key="i">
                        {{ arr }}
                      </li>
                    </ul>
                    <br />
                  </span>
                </b-alert>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/master/pabrik')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import itemTemplate from '@/view/pages/item-template.vue'

export default {
  mixins: [validationMixin],
  name: 'editequipment',
  data() {
    return {
      form: {
        pabrik_id: null,
        plant_id: null,
        nama_equipment: null,
        nomor_equipment: null,
        manufacturer: null,
        design_code: null,
        jenis_equipment: null,
        tgl_mulai_operasi: null,
        penggantian_ke: null,
        penggantian_terakhir: null,
      },
      pabrik: [],
      plant: [],
      design_code: [],
      jenis_equipment: [],

      itemDesignCode: {},
      itemJenisEquipment: {},
      itemTemplate,

      loading: false,
      id: 0,

      errors: [],
      showDismissibleAlert: false,
    }
  },
  validations: {
    form: {
      pabrik_id: {
        required,
      },
      plant_id: {
        required,
      },
      nama_equipment: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Master', route: '/master/equipment' },
      { title: 'Edit Equipment' },
    ])
  },
  created() {
    var self = this
    this.id = this.$route.params.id
    ApiService.get('/master/pabrik/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var pabrik = data.data
          for (let i = 0; i < pabrik.length; i++) {
            self.pabrik.push({
              value: pabrik[i].id_pabrik,
              text: pabrik[i].nama_pabrik,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })
      
    ApiService.get('/master/equipment/edit/' + self.id)
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.form.pabrik_id = data.data.pabrik_id
          self.getPlant()
          console.log(data.data.tgl_mulai_operasi)
          console.log(data.data.penggantian_terakhir)
          self.form.nama_equipment = data.data.nama_equipment
          self.form.nomor_equipment = data.data.nomor_equipment
          self.form.manufacturer = data.data.manufacturer
          self.form.design_code = data.data.id_design_code
          self.itemDesignCode = {
            id: data.data.id_design_code,
            name: data.data.code_name,
          }
          self.itemJenisEquipment = {
            id: data.data.id_jenis_equipment,
            name: data.data.nama_jenis,
          }
          self.form.jenis_equipment = data.data.id_jenis_equipment
          self.form.tgl_mulai_operasi = data.data.tgl_mulai_operasi
          self.form.penggantian_ke = data.data.penggantian_ke
          self.form.penggantian_terakhir = data.data.penggantian_terakhir
          self.form.plant_id = data.data.plant_id
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    getPlant() {
      var self = this
      self.plant.splice(0, self.plant.length)
      ApiService.get('/master/plant/showByPabrik/' + self.$v.form.pabrik_id.$model)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var plant = data.data
            for (let i = 0; i < plant.length; i++) {
              self.plant.push({
                value: plant[i].id_plant,
                text: plant[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this
      self.loading = true
      const pabrik_id = self.$v.form.pabrik_id.$model
      const plant_id = self.$v.form.plant_id.$model
      const nama_equipment = self.$v.form.nama_equipment.$model
      const nomor_equipment = self.form.nomor_equipment
      const manufacturer = self.form.manufacturer

      var design_code = null
      if (typeof self.form.design_code === 'object' && self.form.design_code !== null) {
        design_code = parseInt(self.form.design_code.id)
      } else {
        design_code = self.form.design_code
      }

      var jenis_equipment = null

      if (typeof self.form.jenis_equipment === 'object' && self.form.jenis_equipment !== null) {
        jenis_equipment = parseInt(self.form.jenis_equipment.id)
      } else {
        jenis_equipment = self.form.jenis_equipment
      }

      const tgl_mulai_operasi = self.form.tgl_mulai_operasi
      const penggantian_ke = self.form.penggantian_ke
      const penggantian_terakhir = self.form.penggantian_terakhir

      ApiService.post('/master/equipment/update/' + self.id, {
        pabrik_id: pabrik_id,
        plant_id: plant_id,
        nama_equipment: nama_equipment,
        nomor_equipment: nomor_equipment,
        manufacturer: manufacturer,
        design_code: design_code,
        jenis_equipment: jenis_equipment,
        tgl_mulai_operasi: tgl_mulai_operasi,
        penggantian_ke: penggantian_ke,
        penggantian_terakhir: penggantian_terakhir,
      })
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.makeToast('success', 'Data berhasil diubah')
            self.$router.push('/master/equipment')
          } else if (data.status == 'validation_error') {
            self.errors = data.errors
            self.showDismissibleAlert = true
            self.loading = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    itemSelected(item) {
      this.form.design_code = item
    },
    setLabel(item) {
      return item.name
    },
    inputChange(text) {
      // your search method
      var self = this
      ApiService.get('/master/equipment/showdesigncode/' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var design_code = data.data
            self.design_code.splice(0, self.design_code.length)

            for (let i = 0; i < design_code.length; i++) {
              self.design_code.push({
                id: design_code[i].id_design_code,
                name: design_code[i].code_name,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      this.form.design_code = text
    },
    itemSelectedJenisEquipment(item) {
      this.form.jenis_equipment = item
    },
    inputChangeJenisEquipment(text) {
      // your search method
      var self = this
      ApiService.get('/master/equipment/showjenis/' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var jenis_equipment = data.data
            self.jenis_equipment.splice(0, self.jenis_equipment.length)

            for (let i = 0; i < jenis_equipment.length; i++) {
              self.jenis_equipment.push({
                id: jenis_equipment[i].id_jenis_equipment,
                name: jenis_equipment[i].nama_jenis,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      this.form.jenis_equipment = text
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
